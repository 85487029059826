import {
  Header,
  FlexCenterSpacedDiv,
  Link,
  Button,
  FlexDiv,
  Margin,
  Content,
} from "@cegal/ds-components";
import { ArrowBack } from "@cegal/ds-icons";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../utils/query";

function PageHeader() {
  const { language } = useQueryParams();
  const { t } = useTranslation();

  return (
    <Header data-theme="cegal-dark" className="page-header">
      <FlexDiv>
        <Margin className="page-padding" />
        <Content padding="1" className="content-width-guard">
          <FlexCenterSpacedDiv>
            <Button
              as={Link}
              href={`https://www.cegal.com/${language || "en"}/`}
              variant="tertiary"
              icon={<ArrowBack size="1.5rem" />}
              style={{ textDecoration: "none" }}
            >
              {t("header-back")}
            </Button>
            <img
              src="/logo.png"
              alt="CEGAL logo"
              width="40%"
              className="logo"
              style={{ paddingRight: "1rem" }}
            />
          </FlexCenterSpacedDiv>
        </Content>
        <Margin className="page-padding" />
      </FlexDiv>
    </Header>
  );
}

export default PageHeader;
