import { DATE_BOOST_OPTIONS, SOURCE_OPTIONS } from "../../constants";
import {
  TagFilterMode,
  type Filters,
  type SourceItem,
  type TagFilter,
} from "../../@types/types";
import {
  PileDiv,
  FlexCenterSpacedDiv,
  Heading,
  Button,
  VerticalSpace,
  Select,
  CheckboxGroup,
  Checkbox,
  Dropdown,
} from "@cegal/ds-components";
import { Close } from "@cegal/ds-icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface FilterListProps {
  filters: Filters;
  initialFilters: Filters;
  prevFilters: Filters;
  setFilters: (value: {
    [key: string]: string | SourceItem[] | TagFilter;
  }) => void;
  applyFilters: () => void;
  resetFilters: () => void;
  closeButton?: React.ReactNode;
  buttonsAreToggles?: boolean;
}

const convertSourceIdsToItems = (ids: string[]): SourceItem[] => {
  const types: SourceItem[] = ids.map(
    (id) => SOURCE_OPTIONS.find((s) => s.id === id)!
  );
  return types;
};

const getItemsTags = (
  selected: string[],
  isGeneralSelected: boolean
): string[] => {
  // If general is NOT selected, convert selected ids to items, else get the unselected items.
  const items: SourceItem[] = !isGeneralSelected
    ? convertSourceIdsToItems(selected)
    : SOURCE_OPTIONS.filter(
        (source) => !selected.find((selected) => selected === source.id)
      );
  const tags = items.map((item) => item.tags || []).flat();
  return tags;
};

function FilterList({
  filters,
  initialFilters,
  prevFilters,
  setFilters,
  applyFilters,
  resetFilters,
  closeButton,
  buttonsAreToggles,
}: FilterListProps) {
  const { t } = useTranslation();

  const [sources, setSources] = useState<string[]>(
    filters.sources.map((s) => s.id)
  );
  const [disableReset, setDisableReset] = useState<boolean>(true);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const handleSourceChange = (selected: string[]) => {
    setSources(selected);
    const isGeneralSelected = selected.includes("general");
    setFilters({
      sources: convertSourceIdsToItems(selected),
      tags: {
        list: getItemsTags(selected, isGeneralSelected),
        // If general is selected, BLACKLIST the tags of the UNSELECTED sources,
        // else WHITELIST the tags of the SELECTED sources
        mode: isGeneralSelected
          ? TagFilterMode.BLACKLIST
          : TagFilterMode.WHITELIST,
      },
    });
  };

  const handleResetFilters = () => {
    setSources(initialFilters.sources.map((s) => s.id));
    resetFilters();
  };

  useEffect(() => {
    const areEqual =
      filters.sortBy === prevFilters.sortBy &&
      filters.dateBoost === prevFilters.dateBoost &&
      filters.sources.sort().join("") === prevFilters.sources.sort().join("");
    setDisableSubmit(areEqual);
  }, [filters, prevFilters]);

  useEffect(() => {
    const areEqual =
      filters.sortBy === initialFilters.sortBy &&
      filters.dateBoost === initialFilters.dateBoost &&
      filters.sources.length === initialFilters.sources.length;
    setDisableReset(areEqual);
  }, [filters, initialFilters]);

  return (
    <PileDiv style={{ minWidth: "15rem" }} data-theme="cegal-dark">
      <FlexCenterSpacedDiv>
        <Heading size="small" level="2">
          {t("filter-menu-heading")}
        </Heading>
        {closeButton ? (
          closeButton
        ) : (
          <Button
            aria-label={t("filter-menu-close")}
            as={buttonsAreToggles && Dropdown.Toggle}
            variant="secondary"
            size="small"
            icon={<Close size="1.5rem" />}
          ></Button>
        )}
      </FlexCenterSpacedDiv>
      <VerticalSpace />
      <Select
        label={t("filter-menu-sort-by.label")}
        value={filters.sortBy}
        onChange={(e) => setFilters({ sortBy: e.target.value })}
      >
        <option value="ranking">{t("filter-menu-sort-by.ranking")}</option>
        <option value="date">{t("filter-menu-sort-by.date")}</option>
      </Select>
      <VerticalSpace />
      <CheckboxGroup
        legend={t("filter-menu-from-source.label")}
        value={sources}
        onChange={handleSourceChange}
      >
        {SOURCE_OPTIONS.map((source) => (
          <Checkbox value={source.id} key={source.id}>
            {t(`filter-menu-from-source.${source.id}`)}
          </Checkbox>
        ))}
        <Button
          variant="secondary"
          size="xsmall"
          disabled={!sources.length}
          onClick={() => handleSourceChange([])}
          style={{ marginTop: "0.5rem" }}
          spacing
        >
          {t("filter-menu-from-source.uncheck-all")}
        </Button>
      </CheckboxGroup>
      <VerticalSpace />
      <Select
        label={t("filter-menu-prioritize-from.label")}
        value={filters.dateBoost}
        onChange={(e) => setFilters({ dateBoost: e.target.value })}
      >
        {DATE_BOOST_OPTIONS.map((option) => (
          <option value={option.value} key={option.value}>
            {t(`filter-menu-prioritize-from.${option.label}`)}
          </option>
        ))}
      </Select>
      <VerticalSpace />
      <FlexCenterSpacedDiv>
        <Button
          as={buttonsAreToggles && Dropdown.Toggle}
          onClick={handleResetFilters}
          variant="secondary"
          disabled={disableReset}
        >
          {t("filter-menu-actions.reset")}
        </Button>
        <Button
          as={buttonsAreToggles && Dropdown.Toggle}
          onClick={applyFilters}
          disabled={disableSubmit}
        >
          {t("filter-menu-actions.apply")}
        </Button>
      </FlexCenterSpacedDiv>
    </PileDiv>
  );
}

export default FilterList;
