import type { ResultItem } from "../@types/types";
import {
  Heading,
  Link,
  BodyLong,
  BodyShort,
  FlexDiv,
  Chip,
} from "@cegal/ds-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface Props {
  item: ResultItem;
  reduceClampLimit?: boolean;
}

const Container = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const Title = styled(Heading)`
  font-size: var(--cds-font-body-size-large);
  font-weight: bold;
`;

const TitleLink = styled(Link)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Body = styled(BodyLong)`
  display: -webkit-box;
  cursor: pointer;
  -webkit-line-clamp: ${(props) => (props.$reduceClampLimit ? "2" : "3")};
  line-clamp: ${(props) => (props.$reduceClampLimit ? "2" : "3")};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Timestamp = styled(BodyShort)`
  color: var(--cds-color-cegalblack-lighter30);
`;

function SearchResultItem({ item, reduceClampLimit = false }: Props) {
  const { t } = useTranslation();

  const link = `https://${item.domain}${item.url}`;
  const date = new Date(item.publishedDate || 0).toLocaleDateString();

  return (
    <Container>
      <Title size="xsmall" level="2">
        <TitleLink
          href={link}
          rel="external"
          dangerouslySetInnerHTML={{ __html: item.title }}
        ></TitleLink>
      </Title>
      <Body
        $reduceClampLimit={reduceClampLimit}
        as="blockquote"
        dangerouslySetInnerHTML={{ __html: item.description }}
        onClick={() => (window.location.href = link)}
        tabindex="0"
        data-href={link}
        cite={link}
        role="link"
        rel="external"
      ></Body>
      <Timestamp size="small">
        {item.publishedDate
          ? `${t("search-item-timestamp")} ${date}`
          : t("search-item-timestamp-unknown")}
      </Timestamp>
      {item.tags && (
        <FlexDiv>
          {item.tags.slice(0, 3).map((tag: string) => (
            <Chip size="small" key={`${item.id}-${tag}`}>
              {tag}
            </Chip>
          ))}
        </FlexDiv>
      )}
    </Container>
  );
}

export default SearchResultItem;
