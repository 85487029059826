import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./en/translation.json";
import norsk from "./no/translation.json";
import dansk from "./da/translation.json";
import svenska from "./se/translation.json";

i18next.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  debug: false,
  resources: {
    en: { translation: english },
    no: { translation: norsk },
    da: { translation: dansk },
    se: { translation: svenska },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});
