export type SearchResult = {
  total: number;
  offset: number;
  limit: number;
  results: ResultItem[];
  cacheKey: string;
  searchTerm: string;
  page: number;
};

export type ResultItem = {
  id: number;
  score: number;
  type: string;
  domain: string;
  url: string;
  featuredImageUrl: string;
  language: string;
  title: string;
  description: string;
  authorFullName: string;
  tags: string[] | undefined;
  publishedDate?: number;
};

export type SourceItem = {
  id: string;
  tags?: string[];
  types: string[];
};

export type SortBy = "ranking" | "date";
export type DateBoost = "any" | "7d" | "30d" | "365d";
export enum TagFilterMode {
  WHITELIST = "whitelist",
  BLACKLIST = "blacklist",
}
export type TagFilter = {
  list: string[];
  mode: TagFilterMode;
};

export type Filters = {
  sortBy: SortBy;
  sources: SourceItem[];
  dateBoost: DateBoost;
  tags: TagFilter;
};
