import { Search } from "@cegal/ds-components";
import { useTranslation } from "react-i18next";

interface Props {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

function SearchInputField({ searchTerm, setSearchTerm }: Props) {
  const { t } = useTranslation();

  return (
    <Search
      label={t("search-input-label")}
      placeholder={t("search-input-label")}
      hideLabel={true}
      variant="primary"
      defaultValue={searchTerm}
      onSubmit={setSearchTerm}
    />
  );
}

export default SearchInputField;
