import { SourceItem } from "../@types/types";

interface Params {
  [key: string]: string;
}

const { pathname } = window.location;

function paramsToObject(urlParams: URLSearchParams): Partial<Params> {
  const result: Partial<Params> = {};
  for (const [key] of urlParams.entries()) {
    const all = urlParams.getAll(key);
    result[key] = all.length > 1 ? all.join(",") : all[0];
  }
  return result;
}

export const useQueryParams = (): Partial<Params> => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  return paramsToObject(urlParams);
};

export const deleteQueryParam = (param: string) => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  urlParams.delete(param);
  const newUrl = `${pathname}?${urlParams.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const updateQueryParam = (param: string, value: string) => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  urlParams.set(param, value);
  const newUrl = `${pathname}?${urlParams.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const updateQuerySources = async (items: SourceItem[]) => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  const types: string[] = [...new Set(items.map((item) => item.types).flat())];

  urlParams.delete("type");
  types.forEach((type) => {
    urlParams.append("type", type);
  });

  const newUrl = `${pathname}?${urlParams.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const getQuerySources = (): string[] => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  return urlParams.getAll("type");
};
