import { Dropdown, Button } from "@cegal/ds-components";
import { FilterList as FilterIcon } from "@cegal/ds-icons";
import { useTranslation } from "react-i18next";
import FilterList, { FilterListProps } from "./FilterList";

function FilterMobile({
  filters,
  initialFilters,
  prevFilters,
  setFilters,
  applyFilters,
  resetFilters,
}: FilterListProps) {
  const { t } = useTranslation();

  const hasActiveFilters =
    filters.sortBy !== initialFilters.sortBy ||
    filters.dateBoost !== initialFilters.dateBoost ||
    filters.sources.length !== initialFilters.sources.length;

  return (
    <div id="filter-container--xs-screens">
      <Dropdown aria-label={t("filter-menu-heading")}>
        <Button
          as={Dropdown.Toggle}
          variant="secondary"
          aria-label={t("filter-button-title")}
          size="small"
          className={{ "filter-button--active-filters": hasActiveFilters }}
          icon={<FilterIcon size="1.5rem" />}
        ></Button>
        <Dropdown.Menu data-theme="cegal-dark">
          <FilterList
            filters={filters}
            initialFilters={initialFilters}
            prevFilters={prevFilters}
            setFilters={setFilters}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
            buttonsAreToggles
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default FilterMobile;
