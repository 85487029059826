import React, { useState, useEffect } from "react";
import {
  Container,
  FlexDiv,
  Margin,
  Content,
  Heading,
  FlexCenterSpacedDiv,
  VerticalSpace,
  Loader,
  PileCenterDiv,
  BodyShort,
  Row,
  Column,
} from "@cegal/ds-components";
import "./i18n/config";
import "./App.css";
import FilterXsScreens from "./components/filter/FilterXsScreens";
import { useTranslation } from "react-i18next";
import SearchInputField from "./components/SearchInputField";
import {
  DateBoost,
  Filters,
  ResultItem,
  SearchResult,
  SortBy,
  TagFilterMode,
} from "./@types/types";
import SearchResultItem from "./components/SearchResultItem";
import {
  useQueryParams,
  updateQueryParam,
  updateQuerySources,
  deleteQueryParam,
  getQuerySources,
} from "./utils/query";
import ResultListPagination from "./components/ResultListPagination";
import PageHeader from "./components/PageHeader";
import { DEFAULT_FILTERS } from "./constants";
import FilterMScreens from "./components/filter/FilterMScreens";
import FilterLScreens from "./components/filter/FilterLScreens";

let initialFilters: Filters;

const getInitialFilters = (
  boostRecent: DateBoost,
  sortBy: SortBy
): Filters => ({
  ...DEFAULT_FILTERS,
  dateBoost: boostRecent ? boostRecent : DEFAULT_FILTERS.dateBoost,
  sortBy: sortBy ? sortBy : DEFAULT_FILTERS.sortBy,
});

function App() {
  const { t, i18n } = useTranslation();
  const { term, language, boostRecent, sortBy } = useQueryParams();
  if (!initialFilters) {
    initialFilters = getInitialFilters(
      boostRecent as DateBoost,
      sortBy as SortBy
    );
  }

  const [results, setResults] = useState<SearchResult | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(term || "");
  const [prevFilters, setPrevFilters] = useState<Filters>(
    getInitialFilters(boostRecent as DateBoost, sortBy as SortBy)
  );
  const [filters, setFilters] = useState<Filters>(
    getInitialFilters(boostRecent as DateBoost, sortBy as SortBy)
  );
  const [page, setPage] = useState<number>(1);

  const fetchSearchResults = async () => {
    if (!searchTerm || isLoading) return;

    setIsLoading(true);
    const filterOnTags: string[] = filters.tags.list;
    const tagFilterMode: TagFilterMode = filters.tags.mode;
    const sortByDate: boolean = filters.sortBy === "date";
    const sourceTypes: string[] = getQuerySources();
    /* Prioritize dateBoost value from filters, set to 60d when sorting by date to boost relevant answers, else ignore */
    const dateBoost: string =
      filters.dateBoost !== "any" ? filters.dateBoost : sortByDate ? "60d" : "";

    try {
      const res = await fetch(
        encodeURI(
          `https://www.cegal.com/_hcms/search?term=${searchTerm}&offset=${
            (page - 1) * (results?.limit || 0)
          }${
            /* Increase limit when filtering on tags or sorting by date to get more relevant answers */
            filterOnTags.length > 0
              ? "&limit=30"
              : sortByDate
              ? "&limit=20"
              : ""
          }${
            sourceTypes && sourceTypes.length > 0
              ? sourceTypes.map((type) => "&type=" + type).join("")
              : ""
          }${dateBoost ? `&boostRecent=${dateBoost}` : ""}&language=${
            language || "en"
          }&analytics=true`
        )
      );

      if (res.ok) {
        const searchResults = await res.json();
        if (filterOnTags.length > 0) {
          searchResults.results = searchResults.results.filter(
            (item: ResultItem) => {
              if (!item.tags) {
                // If item has no tags, return it only if tagFilterMode is BLACKLIST
                return tagFilterMode === TagFilterMode.BLACKLIST;
              }
              const hasTagOverlap = !!item.tags.some((tag) =>
                filterOnTags.includes(tag.toLowerCase().replace(/\s/g, ""))
              );
              // If tagFilterMode is BLACKLIST, return items with no overlap with filterOnTags
              // else if WHITELIST, return items that do have overlap with filterOnTags
              return tagFilterMode === TagFilterMode.BLACKLIST
                ? !hasTagOverlap
                : hasTagOverlap;
            }
          );
        }
        if (sortByDate) {
          searchResults.results.sort((a: ResultItem, b: ResultItem) => {
            return (b?.publishedDate ?? 0) - (a?.publishedDate ?? 0);
          });
        }
        setResults(searchResults);
      }
    } catch (e) {
      console.error(e);
      setResults(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyFilters = () => {
    setPage(1);
    setPrevFilters({ ...filters });
    fetchSearchResults();
  };

  const handleResetFilters = () => {
    setPage(1);
    setPrevFilters({ ...initialFilters });
    setFilters({ ...initialFilters });
    updateQuerySources(filters.sources);
  };

  useEffect(() => {
    updateQueryParam("term", searchTerm);
    fetchSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, page]);

  useEffect(() => {
    if (language) i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    // If filters were reset
    if (
      JSON.stringify(filters) === JSON.stringify(prevFilters) &&
      JSON.stringify(filters) === JSON.stringify(initialFilters)
    ) {
      fetchSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, prevFilters]);

  useEffect(() => {
    if (filters.sortBy === "ranking") deleteQueryParam("sortBy");
    else updateQueryParam("sortBy", filters.sortBy);
  }, [filters.sortBy]);

  useEffect(() => {
    updateQuerySources(filters.sources);
  }, [filters.sources]);

  useEffect(() => {
    if (filters.dateBoost === "any") deleteQueryParam("boostRecent");
    else updateQueryParam("boostRecent", filters.dateBoost);
  }, [filters.dateBoost]);

  return (
    <Container data-theme="cegal-light" className="App">
      <PageHeader />
      <FlexDiv>
        <Margin className="page-padding" />
        <Content padding="1" className="content-width-guard">
          <FlexCenterSpacedDiv>
            <Heading size="large" level="1">
              {t("results-title")}
            </Heading>
            <FilterXsScreens
              filters={filters}
              initialFilters={initialFilters}
              prevFilters={prevFilters}
              setFilters={(e) => {
                const newState = {
                  ...filters,
                  ...e,
                };
                setFilters(newState);
              }}
              applyFilters={handleApplyFilters}
              resetFilters={handleResetFilters}
            />
          </FlexCenterSpacedDiv>
          <VerticalSpace />
          <Row>
            <Column>
              <SearchInputField
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <VerticalSpace />
              <main aria-label={t("results-title")}>
                {isLoading ? (
                  <PileCenterDiv>
                    <VerticalSpace size="2" />
                    <Loader />
                  </PileCenterDiv>
                ) : results && results.total > 0 ? (
                  <>
                    <ol start={1}>
                      {results.results.map((res: ResultItem, index: number) => (
                        <li id={(index + 1).toString()} key={res.id}>
                          <SearchResultItem item={res} />
                        </li>
                      ))}
                    </ol>
                    <VerticalSpace size="2" />
                    <ResultListPagination
                      aria-label={t("pagination-label")}
                      total={results.total}
                      limit={results.limit}
                      page={page}
                      setPage={setPage}
                    />
                  </>
                ) : (
                  <PileCenterDiv>
                    <VerticalSpace size="2" />
                    <BodyShort>{t("no-results")}</BodyShort>
                  </PileCenterDiv>
                )}
              </main>
            </Column>
            <Column style={{ flex: "initial", margin: 0 }}>
              <FilterMScreens
                filters={filters}
                initialFilters={initialFilters}
                prevFilters={prevFilters}
                setFilters={(e) => {
                  const newState = {
                    ...filters,
                    ...e,
                  };
                  setFilters(newState);
                }}
                applyFilters={handleApplyFilters}
                resetFilters={handleResetFilters}
              />
              <FilterLScreens
                filters={filters}
                initialFilters={initialFilters}
                prevFilters={prevFilters}
                setFilters={(e) => {
                  const newState = {
                    ...filters,
                    ...e,
                  };
                  setFilters(newState);
                }}
                applyFilters={handleApplyFilters}
                resetFilters={handleResetFilters}
              />
            </Column>
          </Row>
        </Content>
        <Margin className="page-padding" />
      </FlexDiv>
    </Container>
  );
}

export default App;
