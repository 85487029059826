import {
  BodyLong,
  Container,
  Content,
  FlexDiv,
  Heading,
  Link,
  Margin,
  PileCenterDiv,
  VerticalSpace,
} from "@cegal/ds-components";
import PageHeader from "./PageHeader";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const ErrorDetails = styled.div`
  padding: 1rem;
  background-color: var(--cds-color-cegalblack-lighter95);
  border-radius: 0.5rem;
`;

interface Props {
  error: any;
  componentStack: any;
  resetError: () => void;
}

function ErrorPage({ error, componentStack, resetError }: Props) {
  const { t } = useTranslation();

  return (
    <Container data-theme="cegal-light" className="App">
      <PageHeader />
      <FlexDiv>
        <Margin className="page-padding" />
        <Content padding="1" className="content-width-guard">
          <PileCenterDiv>
            <Heading size="xlarge" level="1">
              {t("error-page.header")}
            </Heading>
          </PileCenterDiv>
          <VerticalSpace />
          <BodyLong>
            <Trans i18nKey="error-page.body">
              We encountered an error. Please try
              <Link href="#" onClick={() => resetError()}>
                refreshing the page
              </Link>
              . If the error persists, don't hesitate to contact us at
              <Link href="mailto:marketing@cegal.com">marketing@cegal.com</Link>
              and include the error message below in your e-mail.
            </Trans>
          </BodyLong>
          <VerticalSpace size="2" />
          <ErrorDetails>
            <code>
              {error.toString()}
              <br />
              {componentStack}
            </code>
          </ErrorDetails>
        </Content>
        <Margin className="page-padding" />
      </FlexDiv>
    </Container>
  );
}

export default ErrorPage;
