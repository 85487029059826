import { useEffect, useRef, useState } from "react";
import { Button, Panel } from "@cegal/ds-components";
import { ArrowOutward, FilterList as FilterIcon } from "@cegal/ds-icons";
import FilterList, { FilterListProps } from "./FilterList";
import DynamicContainer from "../DynamicContainer";
import { useTranslation } from "react-i18next";

function FilterMScreens({
  filters,
  initialFilters,
  prevFilters,
  setFilters,
  applyFilters,
  resetFilters,
}: FilterListProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const firstRender = useRef(true);
  const openBtnRef = useRef<HTMLButtonElement>();
  const closeBtnRef = useRef<HTMLButtonElement>();

  const hasActiveFilters =
    filters.sortBy !== initialFilters.sortBy ||
    filters.dateBoost !== initialFilters.dateBoost ||
    filters.sources.length !== initialFilters.sources.length;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (open) closeBtnRef.current?.focus();
    else openBtnRef.current?.focus();
  }, [open]);

  return (
    <div
      id="filter-container--m-screens"
      style={{ paddingLeft: open ? "1rem" : "" }}
    >
      {/* This weird conditional structure is necessary for button to render if first page load is outside it's defined CSS width scope (medium screens) */}
      {!open && (
        <Button
          ref={openBtnRef}
          aria-label={t("filter-menu-heading")}
          aria-expanded="false"
          onClick={() => setOpen(true)}
          variant="secondary"
          className={{ "filter-button--active-filters": hasActiveFilters }}
          icon={<FilterIcon size="1.5rem" />}
        ></Button>
      )}
      <DynamicContainer>
        {open && (
          <Panel data-theme="cegal-dark">
            <FilterList
              filters={filters}
              initialFilters={initialFilters}
              prevFilters={prevFilters}
              setFilters={setFilters}
              applyFilters={applyFilters}
              resetFilters={resetFilters}
              closeButton={
                <Button
                  ref={closeBtnRef}
                  aria-label={t("filter-menu-close")}
                  aria-expanded="true"
                  variant="secondary"
                  size="small"
                  icon={<ArrowOutward size="1.5rem" />}
                  onClick={() => setOpen(false)}
                ></Button>
              }
            />
          </Panel>
        )}
      </DynamicContainer>
    </div>
  );
}

export default FilterMScreens;
