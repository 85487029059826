import { PileCenterDiv, Pagination } from "@cegal/ds-components";
/* import { useTranslation } from "react-i18next"; */

interface Props {
  total: number;
  limit: number;
  page: number;
  setPage: (page: number) => void;
}

function ResultListPagination({ total, limit, page, setPage }: Props) {
  /* const { t } = useTranslation();

  const start = page * limit - (limit - 1);
  const end = Math.min(start + limit - 1, total); */

  return (
    <PileCenterDiv>
      {/* Removed in this version, uncomment when pagination is handled in frontend */}
      {/* <BodyShort spacing>
        {t("results-pagination-details", { start, end, total })}
      </BodyShort> */}
      <Pagination
        count={Math.ceil(total / limit)}
        page={page}
        onPageChange={setPage}
      />
    </PileCenterDiv>
  );
}

export default ResultListPagination;
