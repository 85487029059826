import { TagFilterMode, type Filters } from "./@types/types";

/*
 * Note; tags added here should always be lowercase and contain no
 * whitespace so the filtering logic works correctly
 */
const news = {
  id: "news",
  tags: ["news"],
  types: ["BLOG_POST"],
};
const successtories = {
  id: "successtories",
  tags: [
    "successstories",
    "succéhistorier",
    "suksesshistorier",
    "succeshistorier",
  ],
  types: ["BLOG_POST"],
};
const techblog = {
  id: "techblog",
  tags: ["techblog"],
  types: ["BLOG_POST"],
};
const dictionary = {
  id: "dictionary",
  tags: ["dictionary", "ordbok", "ordbog"],
  types: ["BLOG_POST"],
};
const meetourteam = {
  id: "meetourteam",
  tags: ["meetourteam"],
  types: ["BLOG_POST"],
};
const general = {
  id: "general",
  types: ["SITE_PAGE", "LISTING_PAGE"],
};
const sources = [
  news,
  successtories,
  techblog,
  dictionary,
  meetourteam,
  general,
];

export const DEFAULT_FILTERS: Filters = {
  sortBy: "ranking",
  sources,
  dateBoost: "any",
  tags: {
    list: [],
    mode: TagFilterMode.BLACKLIST,
  },
};

export const SOURCE_OPTIONS = sources;

export const DATE_BOOST_OPTIONS = [
  { label: "any", value: "any" },
  { label: "week", value: "7d" },
  { label: "month", value: "30d" },
  { label: "year", value: "365d" },
];
