import { useEffect, useRef, useState } from "react";

function DynamicContainer({
  children,
  className,
  style,
}: React.HTMLAttributes<HTMLDivElement>) {
  const content = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (content.current) setRect(content.current.getBoundingClientRect());
  }, [children]);

  return (
    <div
      className={className}
      style={{
        transition: "0.3s",
        height: `${rect.height}px`,
        width: `${rect.width}px`,
        overflow: "hidden",
        ...style,
      }}
    >
      <div
        ref={content}
        style={{
          width: "fit-content",
          height: "fit-content",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default DynamicContainer;
