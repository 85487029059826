import { Panel } from "@cegal/ds-components";
import FilterList, { FilterListProps } from "./FilterList";
import { useTranslation } from "react-i18next";

function FilterLScreens({
  filters,
  initialFilters,
  prevFilters,
  setFilters,
  applyFilters,
  resetFilters,
}: FilterListProps) {
  const { t } = useTranslation();

  return (
    <aside
      id="filter-container--l-screens"
      aria-label={t("filter-menu-heading")}
    >
      <Panel data-theme="cegal-dark">
        <FilterList
          filters={filters}
          initialFilters={initialFilters}
          prevFilters={prevFilters}
          setFilters={setFilters}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          closeButton={<></>}
        />
      </Panel>
    </aside>
  );
}

export default FilterLScreens;
